import React from "react"

import AuthenticateFooter from "../components/LoginRegister/AuthenticateFooter"
import AuthenticateHeader from "../components/LoginRegister/AuthenticateHeader"
import ForgotPasswordConfirm from "../components/LoginRegister/ForgotPasswordConfirm"

import "../components/LoginRegister/Authenticate.scss"

const ForgotPasswordConfirmPage = () => {
  return (
    <div className="authenticate">
      <AuthenticateHeader />
      <div className="authenticate__main">
        <ForgotPasswordConfirm
          title="Forgot Password"
          button="Confirm new password"
        />
      </div>
      <AuthenticateFooter />
    </div>
  )
}

export default ForgotPasswordConfirmPage
