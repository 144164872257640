import React, { useContext } from "react"

import Check from "../../assets/images/Icons/check.svg"
import { AuthenticationContext } from "../../context"
import Link from "../../utils/link"

import "./ForgotPasswordConfirm.scss"

const ForgotPasswordConfirm = () => {
  const { email } = useContext(AuthenticationContext)

  return (
    <div className="forgot-password-confirm">
      <img src={Check} className="forgot-password-confirm__icon" />

      <p className="forgot-password-confirm__title">
        We have sent you a passoword reset email to <br />
        {email}
        <br />
        <br />
        Please confirm this email to activate your account.
      </p>

      <Link to="/" className="forgot-password-confirm__start">
        Start Washing
      </Link>

      <div className="forgot-password-confirm__email-link">
        <a href="mailto:hello@wrlaundry.com">hello@wrlaundry.com</a>
      </div>
    </div>
  )
}

export default ForgotPasswordConfirm
